@import './tokens';

// Material Theme
@import '~@angular/material/theming';
@import './theme';

// Fonts
@import './fonts';

// Material Icons
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

// @section  Tools [ferramentas]
@import '~vv-jornada-core-css/src/import-core-tools';
@import '~vv-jornada-core-css/src/import-core-settings';
@import './settings';

// @section  Generics [genéricos]
@import '~vv-jornada-core-css/src/generics/reset';

// @section  Objects [objetos]
@import '~vv-jornada-core-css/src/objects/wrapper';

// @section  Components [componentes]
@import '~vv-jornada-core-css/src/components/loading';
@import '~vv-jornada-core-css/src/components/breadcrumb';

// @section  Trumps [coringas]
@import '~vv-jornada-core-css/src/trumps/container-wrapper';
@import '~vv-jornada-core-css/src/trumps/container-size';
@import '~vv-jornada-core-css/src/trumps/element-wrapper';
@import '~vv-jornada-core-css/src/trumps/element-size';

// @section Custom Styles
@import './custom';

.titulo {
  position: relative;
  margin-left: 1rem;

  h1 {
    text-transform: none;
  }

  span {
    font-weight: bold;
  }
}

.card {
  margin: 1em 0;
  flex-direction: column;
  border-radius: 10px;
}

.actions {
  button.secondary-action-red,
  button.secondary-action-gray,
  button.secondary-action {
    background-color: transparent;
  }
}

.modal {
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
  min-height: 50vh;
}

.modal:before {
  width: 102vw;
  height: 102vh;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 930;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
}

.modal:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 931;
  background-color: #fff;
  border: 0.0625rem solid #bbb;
  border-radius: 0.25rem;
}

.modal:after,
.modal:before {
  content: '';
  display: inline-block;
}

:after,
:before {
  box-sizing: inherit;
}

.stop-scrolling {
  height: 100% !important;
  overflow: hidden !important;
}
