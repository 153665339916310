// NEW STUFF :))
@font-face {
  font-family: 'Aeonik-Light';
  src: url('/assets/fonts/aeonik/Aeonik-Light.woff') format('woff');
  // font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik-Regular';
  src: url('/assets/fonts/aeonik/Aeonik-Regular.woff') format('woff');
  font-style: normal;
  // font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik-Bold';
  src: url('/assets/fonts/aeonik/Aeonik-Bold.woff') format('woff');
  font-style: normal;
  // font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik-Black';
  src: url('/assets/fonts/aeonik/Aeonik-Black.woff') format('woff');
  font-style: normal;
  // font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons/Material-Icons-Outlined.woff') format('woff');
  font-display: swap;
}
