body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(/assets/images/arena/bg.png) url(/assets/images/arena/logo.png) url(/assets/images/arena/performance.jpg)
    url(/assets/images/arena/sortimentos.jpg) url(/assets/images/arena/verbas.jpg) url(/assets/images/login/bg.png)
    url(/assets/images/login/logo.png);
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0.2em;
}

body::-webkit-scrollbar-track {
  background: #acadaf;
}

body::-webkit-scrollbar-thumb {
  background: #666b7a;
}

body {
  margin: 0;
  border-bottom: none;
  background: #f9f9f9;
  font-family: 'Aeonik-Regular';
  font-display: swap;
  color: $color-neutral-medium;
  transform: scale(1);
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
}

.spn_hol {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: #fff;
  cursor: progress;
  z-index: 50000;
  opacity: 0.3;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  overflow-y: hidden !important;

  * {
    overflow-y: hidden !important;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 50%;
  margin-left: -35px;
  height: 24px;
  width: 70px;
  text-align: center;
  display: block;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: $white;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-display: swap;
}

.material-icons-regular {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  font-display: swap;
}

.mat-badge-content {
  background: #ffb700;
  color: #004800;
  border-radius: 0.1875em;
  margin-bottom: -2em;
  top: 10% !important;
}

.titulo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;

  h1 {
    color: $color-brand-primary-medium;
    margin-bottom: 0;
    padding-bottom: 0.481em;
    font-weight: 400;
    font-size: 1.3em;
    border-bottom: 0.1875em solid;
    border-image: linear-gradient(to right, $color-brand-secondary-medium 65%, #ffffff00 35%) 100% 1;
    padding-right: 0.5em;
    text-transform: uppercase;
  }

  h4 {
    padding-left: 1em;
    color: $color-neutral-medium;
    font-size: 0.938em;
    opacity: 0.7;
  }
}

.col1 {
  width: 8.333%;
}

.col2 {
  width: 16.6%;
}

.col3 {
  width: 25%;
}

.col4 {
  width: 33.3%;
}

.col5 {
  width: 41.6%;
}

.col6 {
  width: 50%;
}

.col7 {
  width: 58.333%;
}

.col8 {
  width: 58.333%;
}

.col9 {
  width: 75%;
}

.col10 {
  width: 83.2%;
}

.col11 {
  width: 91.667%;
}

.col12 {
  width: 100%;
}

.acoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mat-form-field-outline {
  background: $white;
  border-radius: 0.5rem;
}

.card {
  border-radius: 0.5em;
  background: white;
  border: 1px solid #e2e2e2;
  padding: 1.5em;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  display: flex;
}

.mat-header-cell {
  color: #009200;
}

.mat-cell {
  color: #6a6a6a;
}

.mat-cell,
.mat-header-cell {
  font-size: 0.7em;
  font-weight: 400;
}

.acoes {
  margin-top: 2em;
}

.mat-paginator {
  margin-top: 1em;
  display: flex !important;
  justify-content: center !important;
  background: none;
}

mat-dialog-container {
  padding: 0;
  width: 50vw;
}

.mat-dialog-content,
#loader {
  overflow-y: hidden !important;
}

.mat-checkbox-frame {
  border: 2px solid $color-brand-primary-medium !important;
}
.mat-checkbox-label {
  font-size: 1em;
  font-weight: 400;
}
.mat-checkbox-checkmark-path {
  stroke: $white;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  border: 1px solid $color-brand-primary-medium !important;
  background-color: $color-brand-primary-medium;
}

.mat-select,
input {
  // line-height: 2.5em;
  font-size: 0.8125em;
  // border: 1px solid #cccccc;
  // border-radius: 0.45em;
  // padding: 0.2em 0.5em;
  // color: #2e2e2e;
  width: 100%;
  font-family: 'Aeonik-Regular' !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #e2e2e2;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $color-brand-primary-medium;
}

.mat-sort-header-arrow {
  color: #ff9e00;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header-title {
  color: #006700;
  font-weight: 500;
  justify-content: center;
}

.mat-header-row {
  border-color: #009200;
}

a {
  color: $white;
}

a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: $white;
}

.containerExterno {
  max-width: 72.5em;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  header,
  nav {
    background: #006700;
    min-width: 100% !important;
    position: sticky !important;
    padding-left: 1rem;
  }
  .containerExterno {
    width: 100%;
  }

  .titulo {
    margin-left: 1rem;
    h4 {
      margin-top: 0.6rem;
    }
  }
}

.mat-row {
  border: none;
}

pagination-controls {
  .ngx-pagination {
    background: $white;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    // min-width: 1.875em;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    margin: 0 !important;

    &:first-child {
      border-top-left-radius: 0.4em;
      border-bottom-left-radius: 0.4em;
      border-left: 1px solid #cccccc;
    }

    &:last-child {
      border-top-right-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
      border-left: none;
    }
  }

  a,
  span {
    color: #009200;
  }

  .current {
    background: #009200 !important;
    margin-left: -1px !important;
    font-size: 1em;
    border: 1px solid #009200;

    span {
      color: $white;
      font-weight: 200;
    }
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    margin-right: 0 !important;
    color: #009200;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    margin-left: 0 !important;
    color: #009200;
  }
}

.limite {
  display: flex;
  flex-direction: row;
  align-items: center;

  span,
  select {
    font-size: 0.75em;
    color: #5c5d5c;
    padding: 0.5em;
  }

  select {
    background: $white;
    border: 1px solid #cccccc;
    border-radius: 0.45em;
  }
}

b {
  font-weight: 400;
}

.paginacao {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 300;
    font-size: 0.75em;
  }

  .mat-select-placeholder {
    font-size: 1em;
  }
}

.controles {
  display: flex;
  height: 2em;
}

.naoClique {
  cursor: not-allowed !important;
}

.clicavel {
  cursor: pointer !important;
}

.desativado {
  cursor: not-allowed !important;
  opacity: 0.5;

  * {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

input:focus,
select:focus,
mat-select:focus,
textarea:focus,
button:focus,
mat-button:focus {
  outline: none !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background: $color-brand-primary-medium;
  border: 1px solid $color-brand-primary-medium !important;
}

.mat-checkbox-frame {
  border: 2px solid $color-brand-primary-medium !important;
}

.mat-checkbox-disabled {
  .mat-checkbox-background {
    border: none !important;
  }
}

.mat-checkbox-checkmark-path {
  stroke: $white;
}

.redondo,
.quadrado {
  cursor: pointer;
}

.redondo {
  .mat-checkbox-frame {
    border-radius: 100% !important;
  }

  .mat-checkbox-checked .mat-checkbox-background {
    border-radius: 100% !important;
  }

  .mat-checkbox-label {
    text-transform: capitalize;
    font-size: 0.75em;
    font-weight: 400;
  }

  .mat-checkbox-background {
    border-radius: 100% !important;
  }
}

.modal {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-width: 40em;
  font-family: 'Poppins';

  header {
    position: relative;
    padding: 1.25rem;
    border-bottom: 0.0625rem solid #d8d8d8;
  }

  i {
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }

  footer {
    width: 100%;
    padding: 1.25rem;
  }
}

a,
button,
.mat-select *,
.mat-option *,
select,
input,
mat-input,
.mat-header-cell .bola {
  cursor: pointer;
}

.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: $white !important;
}

h4,
h3,
h2,
h1 {
  font-family: 'Urbanist', sans-serif;
}

h4,
h3,
h2,
h1,
p,
span,
div {
  cursor: default;
  user-select: none;
}

.mat-select-disabled * {
  cursor: not-allowed;
}

:disabled {
  border: 1px solid #cccccc !important;
  opacity: 0.5;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version */
  cursor: not-allowed;
}

::selection {
  background: transparent;
}

::-moz-selection {
  background: transparent;
}

.mat-select-disabled {
  border: 1px solid #cccccc !important;
  opacity: 0.5;
  cursor: not-allowed;
  color: #2e2e2e;
}

.aviso {
  background: red;
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.6);
}

button {
  font-size: 0.8125em;
  color: $white;
  border-radius: 0.45em;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.825em;
  text-align: center;
  text-transform: capitalize;
  height: 2.5em;
  padding: 0.5em 2em;
  border: none;
  justify-content: center;
  z-index: 900;
}

.secondary-action {
  border: 1px solid $color-brand-primary-medium;
  background: $white;
  color: $color-brand-primary-medium;
}

.secondary-action-red {
  border: 1px solid $color-error-dark;
  background: $white;
  color: $color-error-dark;
}

.secondary-action-red-filled {
  border: 1px solid $color-error-dark;
  background: $color-error-dark;
  color: $color-neutral-lightest;
}

.secondary-action-gray {
  border: 1px solid $color-neutral-medium;
  background: $white;
  color: $color-neutral-medium;
}

.secondary-action-purple {
  border: 1px solid $color-brand-primary-medium;
  background: $color-button-background;
  color: $color-brand-primary-medium;
}

.main-action {
  border: 1px solid $color-brand-primary-medium;
  background: $color-brand-primary-medium;
  color: $white;
}

.green-action {
  background: #009200;
  color: $white;
  border: none;
}

.red-action {
  background: red;
  color: $white;
  border: none;
}

#btnSalvarModal {
  border: 1px solid #009200;
  background: #009200;
  color: $white;
}

#btnLimparModal {
  background: red;
  color: $white;
  border: none;
}

#btnCancelarModal {
  border: none;
  background: red;
  color: $white;
}

#btnSalvar {
  border: none;
  background: #009100;
  color: $white;
}

.acoes {
  margin-top: 0;
  justify-content: flex-end;
  border-top: 0.0625rem solid #d8d8d8;
  box-shadow: none;

  button {
    margin-left: 1em;
    font-weight: 400;
  }
}

.borda-verde {
  border: 1px solid #009200;
}

.linha {
  .campo {
    .mat-select {
      width: 95%;
    }
  }
}

.texto-longo {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.normal {
  pointer-events: default !important;
}

#codigo:disabled {
  border: 1px solid #cccccc !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  color: #2e2e2e !important;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
  overscroll-behavior: contain;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.cdk-overlay-container {
  min-height: 100% !important;
}

.mat-table {
  font-family: 'Aeonik-Regular' !important;
}
