$black: rgba(0, 0, 0, 0.87);
$white: #ffffff;
$menu-background-hover: #859eec;
$submenu-background-hover: #796aff;
$title-expassion-panel: rgba(226, 222, 255, 0.2);
$table-background-header: rgba(226, 222, 255, 0.2);

// Primary
$color-brand-primary-darkest: #0A255B;
$color-brand-primary-dark: #0F3889;
$color-brand-primary-medium: #0033C6;
$color-brand-primary-light: #5A8BED;
$color-brand-primary-lightest: #E8EFFC;

// Secondary
// Secondary
$color-brand-secondary-darkest: #6E0C1B;
$color-brand-secondary-dark: #A51228;
$color-brand-secondary-medium: #E31233;
$color-brand-secondary-light: #F5A3AF;
$color-brand-secondary-lightest: #FCE8EB;

// Neutral
$color-neutral-darkest: #202121;
$color-neutral-dark: #6d6e70;
$color-neutral-medium: #929497;
$color-neutral-light: #dbdcdc;
$color-neutral-lightest: #fcfdfc;

// Success
$color-success-darkest: #3d6a06;
$color-success-dark: #699f10;
$color-success-medium: #a2dd21;
$color-success-light: #d5f477;
$color-success-lightest: #f5fdd2;

// Info
$color-info-darkest: #0a437a;
$color-info-dark: #1a83b7;
$color-info-medium: #35daff;
$color-info-light: #85fbff;
$color-info-lightest: #d6fffa;

// Warning
$color-warning-darkest: #7a5d0c;
$color-warning-dark: #b79521;
$color-warning-medium: #ffdc42;
$color-warning-light: #ffee8d;
$color-warning-lightest: #fffad9;

// Error
$color-error-darkest: #7a0818;
$color-error-dark: #b71816;
$color-error-medium: #ff502d;
$color-error-light: #ffa981;
$color-error-lightest: #ffe8d5;

// Gradients
$color-gradient-colorscale: linear-gradient(151.85deg, #6152e8 22.7%, #00d3c3 96.02%);
$color-gradient-dark: linear-gradient(262.35deg, #929497 0%, #6d6e70 94.08%);
$color-gradient-light: linear-gradient(260.31deg, #fcfdfc -29.87%, #dbdcdc 76.83%);
$color-gradient-header: linear-gradient(118.96deg, #6152e8 29.65%, #00d3c3 131.25%);

// Opacity
$color-opacity-2: rgba(255, 255, 255, 0.2);

// Shadows

$ShadowCards01: drop-shadow(3px 3px 5px rgba(106, 90, 255, 0.07));
$ShadowAlert01: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15));
$ShadowBox01: box-shadow(0 0.1rem 0.5rem rgba(255, 255, 255, 0.2));

// Cards
$color-card-border: #e4ecf2;
$color-card-background: rgba(226, 222, 255, 0.2);

// Buttons
$color-button-background: rgba(226, 222, 255, 0.2);

$colorMap: (
  color-brand-primary: (
    darkest: $color-brand-primary-darkest,
    dark: $color-brand-primary-dark,
    medium: $color-brand-primary-medium,
    light: $color-brand-primary-light,
    lightest: $color-brand-primary-lightest,
  ),
  color-brand-secondary: (
    darkest: $color-brand-secondary-darkest,
    dark: $color-brand-secondary-dark,
    medium: $color-brand-secondary-medium,
    light: $color-brand-secondary-light,
    lightest: $color-brand-secondary-lightest,
  ),
  color-neutral: (
    darkest: $color-neutral-darkest,
    dark: $color-neutral-dark,
    medium: $color-neutral-medium,
    light: $color-neutral-light,
    lightest: $color-neutral-lightest,
  ),
  color-success: (
    darkest: $color-success-darkest,
    dark: $color-success-dark,
    medium: $color-success-medium,
    light: $color-success-light,
    lightest: $color-success-lightest,
  ),
  color-info: (
    darkest: $color-info-darkest,
    dark: $color-info-dark,
    medium: $color-info-medium,
    light: $color-info-light,
    lightest: $color-info-lightest,
  ),
  color-warning: (
    darkest: $color-warning-darkest,
    dark: $color-warning-dark,
    medium: $color-warning-medium,
    light: $color-warning-light,
    lightest: $color-warning-lightest,
  ),
  color-error: (
    darkest: $color-error-darkest,
    dark: $color-error-dark,
    medium: $color-error-medium,
    light: $color-error-light,
    lightest: $color-error-lightest,
  ),
  color-gradient: (
    colorscale: $color-gradient-colorscale,
    dark: $color-gradient-dark,
    light: $color-gradient-light,
    header: $color-gradient-header,
  ),
);

$tokens: (
  color: $colorMap,
);
